// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

* {
  box-sizing: border-box;
  
  font-family: Verdana, sans-serif;

  scroll-margin-top: 90px;

}

:root {
  /** primary **/
  --ion-color-primary: #012169;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3880ff;
  --ion-color-primary-tint: #3171e0;

  /** secondary **/
  --ion-color-secondary: #C8102E;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #424242;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #424242;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {

    
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #424242;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #424242;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #424242;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #424242;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #424242;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    
  }


  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #424242;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #424242;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

ion-item
{
  --padding-end: 16px!important;
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
  --ion-safe-area-right: 0;
  --padding-start: 16px!important;
}
ion-list{
  padding: 0 16px !important; 
}
ion-list-header
{
  padding: 0 !important; 
}
ion-card ion-card
{
  margin: 0 !important;
}
ion-list ion-list
{
  padding: 0 !important;
}
ion-card-header ion-title
{
  padding: 0 !important;
}
ion-title {
  font-weight: 900;
}
ion-label
{
  padding: 0;
}
ion-router-link
{
  cursor: pointer;
  width: max-content;
}
ion-card-content ion-list > ion-item
{
  padding: 7.5px 0;
}
ion-item
{
  padding: 1.5px 0;
  --padding-end: 0;
  --padding-start: 0;
}

ion-card ion-item
{
  --padding-end: 0!important;
  --padding-start: 0!important;
}

ion-card ion-card
{
  width: 100%!important;
}
ion-text
{
  font-size: 16px;
  line-height: 1.6
}
ion-button:active
{
  position: relative;
  top: 2px;
  color: #2dd36f!important;
}
ion-router-link:active
{
  position: relative;
  top: 2px;
  color: #C8102E;
}

ion-button
{
  padding: 0 13px;
}

.no-padding
{
  padding: 0 !important;
}
.no-margin
{
  margin: 0 !important;
}
.ion-items-center
{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ion-items-vertical-end
{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.ion-items-vertical-center
{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.ion-items-horizontal-end
{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.ion-text-center
{
  text-align: center;
}

.ion-items-wrap
{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

a
{
  color: #012169;
}

ion-header
{
  height:78px;
  --height:78px;
  --min-height: 78px;
  min-height:78px;
  --max-height: 78px;
  max-height: 78px;
  
  /* background:#fff;
  --background:#fff; */
  border-bottom: 7.5px solid #C8102E;

  background-image: url('https://cdn.merixstudio.com/static/frontend/images/backgrounds/noise.png');
}

/* https://ui-aurora-cdn.nyc3.cdn.digitaloceanspaces.com/aurora/assets/images/dbaas-hero-bg-9f63f24e4183db84a01e1d8f97044b2c.png */
/* https://images.prismic.io/www-static/4b42ce9a-46ac-4146-a43b-2d0bc6aaa1dc_bg-solutions-managed-services.jpg?auto=compress,format */
ion-header > * {
  height: 100%;

  div {
    height: 100%;
    display: flex;
    align-items: center;
    width: max-content;
  }
  ion-text {
    
    width: max-content;

  }
}

ion-footer
{
  height:60px;
  --height:60px;
  --min-height: 60px;
  min-height:60px;
  --max-height: 60px;
  max-height: 60px;
  background:#C8102E;
  --background:#C8102E;
}

.weight-bold
{
  font-weight: 900;
}

.fancy-list :nth-of-type(1) .fancy-label .fancy-subcard:nth-of-type(1) {

  @media (min-width: 1023px) {
    display: none;
  }

}
.fancy-list :nth-of-type(4) .fancy-label .fancy-subcard:nth-of-type(5) {
  
  @media (min-width: 1023px) {
    display: none;
  }
  
}


.fancy-list {

  :nth-of-type(2), :nth-of-type(3) {

  
    @media (min-width: 1023px) {
      
      .large .fancy-card {
        aspect-ratio: 1 / 1.45;
        height: 100%;
      }
  
    }
  }

}


.fancy-list.fancy :nth-of-type(1) {
  @media (min-width: 1023px) {
    grid-row: 2/3;
  }
  
}
.fancy-list.fancy :nth-of-type(2) {
  @media (min-width: 1023px) {
  grid-row: 1/3;
  }
}
.fancy-list.fancy :nth-of-type(3) {
  @media (min-width: 1023px) {
  grid-row: 2/3;
  }
}
.fancy-list.fancy :nth-of-type(4) {
  @media (min-width: 1023px) {
  grid-row: 2/3;
  }
}

.fancy-list.normal :nth-of-type(1) {
  @media (min-width: 1023px) {
    grid-row: 1/2;
  }
  
}
.fancy-list.normal :nth-of-type(2) {
  @media (min-width: 1023px) {
    grid-row: 1/2;
  }
}
.fancy-list.normal :nth-of-type(3) {
  @media (min-width: 1023px) {
    grid-row: 1/2;
  }
}
.fancy-list.normal :nth-of-type(4) {
  @media (min-width: 1023px) {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}
.fancy-list.normal :nth-of-type(5) {
  @media (min-width: 1023px) {
  grid-column: 2/4;
  grid-row: 2/3;
  }
}

.fancy-list {
  background-color: transparent;

  
  @media (max-width: 1023px) {

    row-gap: 48px;
    padding: 48px !important;
    
  }
}


.ion-4x {
  position: relative;
  font-size: 4em;
}


.photo {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 200px;

  z-index: 12;
}

.gradient 
{
  background: -webkit-linear-gradient(35deg,#012169 0,#C8102E 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    position: relative;
}

ion-card {
  margin: 0;
}



.headline-content
{
  min-height: 20vh;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 0 20px;
  color: #424242;

  ion-text {
    
    font-size: 1.4em;
    font-weight: 600;
    font-family: Verdana, sans-serif;

    @media (min-width: 1023px) {

      font-size: 1.5em;
    }
  }

  ion-label {
    min-height: 300px;
    ion-icon {
      color:#C8102E;
      font-size: 120px;
    }
  }
}

.primary {
  color: #012169;
}
.secondary {
  color: #C8102E;
}
.black {
  color: #000;
}
.dark {
  color: #424242;
}

.extra-font {
  font-size: 32px;
}

.extra-bold {
  font-weight: 900;
}
.medium-bold {
  font-weight: 900;
}

.no-padding {
  padding: 0 !important;
}

.big70 {
  @media (min-width: 768px) {
      
      width: 70%;
  }
}
.helf-page {
  @media (min-width: 1023px) {
      
      width: 50% !important;
  }
}


*::-webkit-scrollbar {
  z-index: 1000 !important;
}

.headline-content {
  ion-title * {
    color: #fff; // #232323 !important;
    font-weight: 900 !important;
  }
}


.right {

  @media (min-width: 768px) {
      
      margin-left: auto;
  }
}
.left {

  @media (min-width: 768px) {
      
      margin-right: auto;
  }
  
}

app-statistic-table {
  @media (min-width: 1023px) {
      
      width: 70%;
      
      margin-left: auto;
      margin-right: auto;
  }
}


app-home, app-auth, app-support, app-dashboard {


  ion-header > * {

      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
      
    /* --ion-background-color: #FAF7F2; */
  }

  ion-content {
    /* --ion-background-color: #FAF7F2; */
    --ion-item-background: none !important;
    --ion-card-background: none !important;

    //--ion-background-image: url('https://cdn.merixstudio.com/static/frontend/images/backgrounds/noise.png')

    --background: none;
  background-image: url('https://cdn.merixstudio.com/static/frontend/images/backgrounds/noise.png');
  //background-position: center center;
  background-repeat: repeat;
  //background-size: cover;

    > * {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }
    section {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 40px;
    }

    article {
      width: 100%;
    }
  
  }
}



ion-header {

  > * {
    width: 100% !important;
    padding: 0;
    display: flex;
    justify-content: space-between;
    --padding-start: 0 !important;
    --padding-end: 0 !important;


    @media (min-width: 1023px) {

      padding: 0 20px;
      
    }
    ion-label {
      
      padding: 0;
      margin-left: auto;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 40px;

      @media (max-width: 500px) {

        display: none;
        
      }

      > div {

        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 7.5px;
      }

      ion-text {
        display: none;
        @media (min-width: 1023px) {

          display: block;
          
        }
      }
    }

  }
}

ion-title {
  min-height: 55px;
  // font-family: Montserrat,sans-serif !important;
  font-family: "Pontiac", Arial, Helvetica, sans-serif !important;

}


ion-input {
  padding: 5.5px;
  background-color: #E8F0FE !important;
  color: #434343;
}


ion-text {
  word-wrap: break-word !important;
  white-space: normal !important;
  
  // font-family: Montserrat,sans-serif !important;
  font-family: "Pontiac", Arial, Helvetica, sans-serif !important;
  
  color: #fff;
}
ion-title {
  word-wrap: break-word !important;
  white-space: normal !important;
  
  // font-family: Montserrat,sans-serif !important;
  font-family: "Pontiac", Arial, Helvetica, sans-serif !important;
  
  color: #fff;
}

h2 {
  
  color: #fff;
}

ion-label {
  
  ion-text {
    @media (min-width: 1023px) {

      font-size: 23px;
      color: #fff;
      
    }
  }
  // font-family: Montserrat,sans-serif !important;
  font-family: "Pontiac", Arial, Helvetica, sans-serif !important;
}
.no-bcg {
  background: transparent !important;
}

.slide {
  background: none;
}
ion-slides {
  background: transparent !important;
}

.pointer {
  cursor: pointer;
}